<template>
  <div class="w-auto" v-if="loading && currentSuscribeData.id">
    <clip-loader :loading="loading" color="primary"></clip-loader>
  </div>
  <div v-else class="">
    <div v-if="currentSuscribeData.avenants">
      <vx-card class="" title="SƆHU">
        <div slot="no-body">
          <vs-tabs>
            <vs-tab label="Avenants">
              <div class="p-4">
                <vs-prompt
                  title="Exportation"
                  class="export-options"
                  @cancel="clearFields"
                  @accept="exportToData"
                  accept-text="Valider"
                  cancel-text="Annuler"
                  @close="clearFields"
                  :active.sync="activePrompt"
                >
                  <vs-input
                    v-model="logo"
                    placeholder="Entrez le nom du fichier..."
                    class="w-full"
                  />
                  <multiselect
                    v-model="selectedFormat"
                    :options="formats"
                    class="my-4"
                    placeholder="--Choisir--"
                    :selectLabel="global.selectLabel"
                    :selectedLabel="global.selectedLabel"
                    :deselectLabel="global.deselectLabel"
                  >
                    <template v-slot:noOptions>
                      <span>{{ global.selectNotFoundData }}</span>
                    </template>
                  </multiselect>

                  <div class="flex">
                    <span class="mr-4">Largeur automatique de cellule:</span>
                    <vs-switch v-model="cellAutoWidth"
                      >Largeur automatique de cellule</vs-switch
                    >
                  </div>
                </vs-prompt>
                <div
                  class="flex flex-wrap-reverse items-center data-list-btn-container"
                >
                  <div>
                    <vs-button
                      v-if="checkUserRole('broker').create"
                      @click="popupAddAvenant = true"
                      color="primary"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                      >Ajouter</vs-button
                    >
                    <vx-tooltip
                      color="primary"
                      :title="role.notAllowTitle"
                      :text="role.notAllowText"
                      v-else
                    >
                      <vs-button
                        :disabled="true"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        >Ajouter</vs-button
                      >
                    </vx-tooltip>
                  </div>
                  <!-- <div>
                    <vs-dropdown
                      
                      class="dd-actions cursor-pointer ml-4"
                    >
                      <div
                        class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                      >
                        <span class="mr-2">Actions</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                      </div>
                      <vs-dropdown-menu>
                        <vs-dropdown-item @click="activePrompt = true">
                          <span class="flex items-center">
                            <feather-icon
                              icon="PrinterIcon"
                              svgClasses="h-4 w-4"
                              class="mr-2"
                            />
                            <span>Exporter</span>
                          </span>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div> -->
                </div>
                <div class>
                  <div class="w-auto" v-if="loading">
                    <clip-loader :loading="loading" color="primary"></clip-loader>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        currentSuscribeData.avenants &&
                        currentSuscribeData.avenants.length > 0
                      "
                    >
                      <vs-table
                        multiple
                        @dblSelection="avenantSelectedDouble"
                        :data="currentSuscribeData.avenants"
                        v-model="avenantSelected"
                        search
                        stripe
                        noDataText="Aucun résultat trouvé"
                      >
                        <template slot="thead">
                          <vs-th sort-key="avenant">N° avenant</vs-th>
                          <vs-th sort-key="startDateContributions">Date d'effet</vs-th>
                          <vs-th sort-key="endDateContributions">Date d'échéance</vs-th>
                          <vs-th sort-key="cotisationTTC">Cotisation TTC</vs-th>
                          <vs-th sort-key="paymentStatus">Statut paiement</vs-th>
                          <vs-th>Actions</vs-th>
                        </template>
                        <template slot-scope="{ data }">
                          <vs-tr
                            :data="tr"
                            :key="indextr"
                            v-for="(tr, indextr) in data"
                          >
                            <vs-td :data="data[indextr].id">{{
                              currentSuscribeData.avenants.length - (indextr + 1)
                            }}</vs-td>
                            <vs-td :data="data[indextr].startDateContributions">{{
                              formatDate(data[indextr].startDateContributions)
                            }}</vs-td>
                            <vs-td :data="data[indextr].endDateContributions">
                              {{
                                formatDate(data[indextr].endDateContributions)
                              }}</vs-td
                            >
                            <vs-td :data="data[indextr].cotisationTTC">{{
                              onFormatAmount(data[indextr].cotisationTTC)
                            }}</vs-td>
                            <vs-td :data="data[indextr].paymentStatus">{{
                              allPaymentStatus.find(
                                (el) => el.id == data[indextr].paymentStatus
                              )
                                ? allPaymentStatus.find(
                                    (el) => el.id == data[indextr].paymentStatus
                                  ).label
                                : ""
                            }}</vs-td>
                            <vs-td :data="data[indextr].id">
                              <div class="inline-flex" @click.stop>
                                <vs-dropdown 
                                  vs-trigger-click
                                  class="dd-actions cursor-pointer"
                                >  
                                  <div class="cursor-pointer">
                                    <feather-icon
                                      icon="AlignJustifyIcon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                  <vs-dropdown-menu class="aps-benin-v2-dropdown">
                                    <div>
                                      <vs-dropdown-item
                                        v-if="data[indextr].paymentStatus == 'required'"
                                      > 
                                        <div
                                          class="ml-1 cursor-pointer"
                                          @click="checkPayed(data[indextr])"
                                        >
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="CreditCardIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Payer</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                      <vx-tooltip
                                        v-else
                                        color="primary"
                                        :title="role.notAllowTitle"
                                        :text="role.notAllowText"
                                      >
                                        <vs-dropdown-item disabled>
                                          <div class="ml-1 cursor-pointer">
                                            <span class="flex items-center">
                                              <feather-icon
                                                class="text-primary"
                                                icon="CreditCardIcon"
                                                svgClasses="w-6 h-6"
                                              ></feather-icon>
                                              <span class="m-3">Payer</span>
                                            </span>
                                          </div>
                                        </vs-dropdown-item>
                                      </vx-tooltip>
                                    </div>
                                    <div>
                                      <vs-dropdown-item
                                        v-if="
                                          data[indextr].paymentStatus == 'payOff' &&
                                          !data[indextr].isDefault
                                        "
                                      >
                                        <div
                                          class="ml-1 cursor-pointer"
                                          @click="checkCancelPayed(data[indextr])"
                                        >
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="DeleteIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Annuler Paiement</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                      <!--<vx-tooltip
                                            v-else
                                            color="primary"
                                            :title="role.notAllowTitle"
                                            :text="role.notAllowText"
                                          >
                                            <vs-dropdown-item disabled>
                                              <div class="ml-1 cursor-pointer">
                                                <span class="flex items-center">
                                                  <feather-icon
                                                    class="text-primary"
                                                    icon="DeleteIcon"
                                                    svgClasses="w-6 h-6"
                                                  ></feather-icon>
                                                  <span class="m-3"
                                                    >Annuler Paiement</span
                                                  >
                                                </span>
                                              </div>
                                            </vs-dropdown-item>
                                          </vx-tooltip>-->
                                    </div>
                                    <div>
                                      <vs-dropdown-item v-if="data[indextr].paymentStatus !== 'payOff'">
                                        <div
                                          class="ml-1 cursor-pointer"
                                          @click="checkUpdateAvenant(data[indextr])"
                                        >
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="Edit2Icon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Modifier</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                      <vx-tooltip
                                        v-else
                                        color="primary"
                                        :title="role.notAllowTitle"
                                        :text="role.notAllowText"
                                      >
                                        <vs-dropdown-item disabled>
                                          <div class="ml-1 cursor-pointer">
                                            <span class="flex items-center">
                                              <feather-icon
                                                class="text-primary"
                                                icon="Edit2Icon"
                                                svgClasses="w-6 h-6"
                                              ></feather-icon>
                                              <span class="m-3">Modifier</span>
                                            </span>
                                          </div>
                                        </vs-dropdown-item>
                                      </vx-tooltip>
                                    </div>

                                    <div>
                                      <vs-dropdown-item>
                                        <div
                                          class="ml-1 cursor-pointer"
                                          @click="getFilesList(data[indextr])"
                                        >
                                          <span class="flex items-center">
                                            <feather-icon 
                                              icon="DownloadIcon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Télécharger </span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </div>

                                    <div>
                                      <vs-dropdown-item
                                        v-if="data[indextr].paymentStatus == 'payOff'"
                                      >
                                        <div
                                          class="ml-1 cursor-pointer"
                                          @click="enabledAvenant(data[indextr])"
                                        >
                                          <span class="flex items-center">
                                            <feather-icon
                                              icon="UnlockIcon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span v-if="data[indextr].status == 'active'" class="m-3"> Relancer </span>
                                            <span v-else class="m-3"> Activer Avenant </span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                      <vx-tooltip
                                        v-else
                                        color="primary"
                                        :title="role.notAllowTitle"
                                        :text="role.notAllowText"
                                      >
                                        <vs-dropdown-item disabled>
                                          <div class="ml-1 cursor-pointer">
                                            <span class="flex items-center">
                                              <feather-icon
                                                icon="UnlockIcon"
                                                class="text-primary"
                                                svgClasses="w-6 h-6"
                                              ></feather-icon>
                                              <span v-if="data[indextr].status == 'active'" class="m-3"> Relancer </span>
                                            <span v-else class="m-3"> Activer Avenant </span>
                                            </span>
                                          </div>
                                        </vs-dropdown-item>
                                      </vx-tooltip>
                                    </div>

                                    <div>
                                      <vs-dropdown-item v-if="indextr == 0">
                                        <div
                                          class="ml-1 cursor-pointer"
                                          @click="checkRemoveAvenant(data[indextr])"
                                        >
                                          <span class="flex items-center">
                                            <feather-icon
                                              icon="Trash2Icon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Supprimer </span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>

                                      <vx-tooltip
                                        v-else
                                        color="primary"
                                        :title="role.notAllowTitle"
                                        :text="role.notAllowText"
                                      >
                                        <vs-dropdown-item disabled>
                                          <div class="ml-1 cursor-pointer">
                                            <span class="flex items-center">
                                              <feather-icon
                                                icon="Trash2Icon"
                                                class="text-primary"
                                                svgClasses="w-6 h-6"
                                              ></feather-icon>
                                              <span class="m-3">Supprimer </span>
                                            </span>
                                          </div>
                                        </vs-dropdown-item>
                                      </vx-tooltip>
                                    </div>
                                  </vs-dropdown-menu>
                                </vs-dropdown>

                                <vx-tooltip
                                  v-if="data[indextr].paymentStatus === 'payOff'"
                                  position="left"
                                  color="primary"
                                  title="Détails paiement"
                                  :text="DetailsPayment(data[indextr], `${item.subscription.subscriber.firstname} ${item.subscription.subscriber.lastname}`)"
                                >
                                  <div class="cursor-pointer ml-6">
                                    <feather-icon
                                      icon="InfoIcon"
                                      class="text-primary"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>

                                <div
                                  class="ml-2"
                                  v-show="woIsAction == data[indextr].id"
                                >
                                  <clip-loader
                                    :loading="
                                      loadingRemoveAvenant || loadingUpdateAvenant
                                    "
                                    color="primary"
                                    size="25px"
                                  ></clip-loader>
                                </div>
                              </div>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                    <div v-else>
                      <div class="flex items-center justify-center mt-8">
                        <strong>Aucun enregistrement</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>

      <vs-popup
        class="holamundo"
        title="Ajouter un avenant"
        :active.sync="popupAddAvenant"
      >
        <div class="m-4">
          <div class="clearfix">
            <div>
              <vs-row vs-w="12">
                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6" 
                  vs-sm="6" 
                  vs-xs="12"
                >
                  <div class="w-full">
                    <label for="" class="vs-input--label"
                      >Durée de couverture*</label
                    >
                    <multiselect
                      v-model="form.durationCoverage"
                      :options="durationCoverageListSelect"
                      placeholder="--Choisir--"
                      label="label"
                      track-by="label"
                      v-validate="'required'"
                      name="Durée de couverture"
                      required
                      disabled
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                      <template v-slot:noOptions>
                        <span>{{ global.selectNotFoundData }}</span>
                      </template>
                    </multiselect>

                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Durée de couverture") }}
                    </div>
                  </div>
                </vs-col>
                
                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      type="date"
                      class="inputx w-full mb-2 mt-2"
                      label="Date d'effet*"
                      v-model="startDateContributions"
                      data-vv-validate-on="blur"
                      name="Date d'effet"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Date d'effet") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      type="date"
                      disabled
                      class="inputx w-full"
                      label="Date d'échéance*"
                      v-model="form.endDateContributions"
                      data-vv-validate-on="blur"
                      name="Date d'échéance"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Date d'échéance") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      type="number"
                      class="aps-benin-v2-form-number inputx w-full mt-2 mb-2"
                      label="Cotisation TTC*"
                      v-model="form.cotisationTTC"
                      v-validate="'required|numeric'"
                      data-vv-validate-on="blur"
                      name="Cotisation TTC"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Cotisation TTC") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      disabled
                      label="Périodicité de paiement"
                      value="Annuelle"
                    />
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  style="flex-direction: column"
                >
                  <div class="w-full">
                    <p class="pl-2 pt-2">Objet*</p>
                    <multiselect
                      v-model="variableGlobalFormOject"
                      :options="allMailVariableList"
                      class="mb-4 mt-2"
                      id="text"
                      placeholder="--Insérer une variable de personnalisation--"
                      label="label"
                      track-by="label"
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                    </multiselect>
                  </div>
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2"
                      v-model="form.object"
                      id="texto"
                      @change="onEditorChangeFormObjet($event)"
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      name="objet"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("objet") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  vs-justify="left"
                  vs-align="left"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="pr-4 mt-2 mb-2"
                >
                  <div class="mb-2">
                    <span>Message*</span>
                  </div>

                  <div class="w-full">
                    <multiselect
                      v-model="variableGlobalForm"
                      :options="allMailVariableList"
                      class="mb-4"
                      placeholder="--Insérer une variable de personnalisation--"
                      label="label"
                      track-by="label"
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                    </multiselect>

                    <quill-editor
                      @focus="onEditorFocusForm($event)"
                      @change="onEditorChangeForm($event)"
                      v-model="form.message"
                    >
                    </quill-editor>
                  </div>
                </vs-col>
                <vs-col class="pr-4 mb-2 mt-4" vs-lg="12" vs-sm="12" vs-xs="12">
                  <div
                    class="w-full"
                    style="
                      display: flex;
                      align-items: flex-end;
                      justify-content: space-between;
                    "
                  >
                    <div>
                      <div>
                        <span class="ml-2">Fichier(s)</span>
                        <div>
                          <div uk-form-custom class="ebsi-upload-file">
                            <input
                              multiple
                              type="file"
                              :value="resetFilesUpload"
                              v-on:change="checkUploadFile"
                            />
                            <button
                              style="cursor: pointer"
                              class="uk-button uk-button-default"
                              type="button"
                              tabindex="-1"
                            >
                              Joindre des fichiers
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="aps-benin-v2-error-msg" v-show="formError">
                        Erreur lors du chargement du fichier
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-3 mb-3"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      class="mb-4"
                      v-if="form.filesname && form.filesname.length > 0"
                    >
                      <p><b>Liste des fichiers</b></p>
                    </div>
                    <div
                      v-for="(file, index) in form.filesname"
                      :key="file.name"
                      class="center"
                    >
                      <div class="flex">
                        {{ index + 1 }})
                        <b class="pl-2">{{ subString(file) }}</b>
                        <vx-tooltip color="primary" text="Supprimer">
                          <div
                            class="ml-8 cursor-pointer"
                            @click="removeFile(index, 'Add')"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              svgClasses="w-6 h-6"
                            ></feather-icon>
                          </div>
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  v-if="displayFileName"
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <div
                    class="inputx w-full flex justify-around"
                    style="align-items: center"
                  >
                    <span>
                      {{ form.fileName }}
                    </span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
          <div class="mt-8">
            <div class="inline-flex">
              <div>
                <vs-button
                  class="w-full sm:w-auto"
                  :disabled="!validateFormAddAvenant || loadingAddAvenant"
                  @click="fetchApplyAddAvenant"
                  >Valider</vs-button
                >

                <vs-button
                  class="w-full sm:w-auto ml-4"
                  type="border"
                  @click="popupAddAvenant = false"
                  >Annuler</vs-button
                >
              </div>
              <div class="ml-2">
                <clip-loader
                  :loading="loadingAddAvenant"
                  color="primary"
                ></clip-loader>
              </div>
            </div>
            <div class="mt-2">
              <p class="text-danger">* Champ obligatoire</p>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        class="holamundo"
        title="Modifier un avenant"
        :active.sync="popupUpdateAvenant"
      >
        <div class="m-4">
          <div class="clearfix">
            <div>
              <vs-row vs-w="12">
                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <label for="" class="vs-input--label"
                      >Durée de couverture*</label
                    >
                    <multiselect
                      v-model="formUpdated.durationCoverage"
                      :options="durationCoverageListSelect"
                      placeholder="--Choisir--"
                      label="label"
                      track-by="label"
                      v-validate="'required'"
                      name="Durée de couverture"
                      required
                      disabled
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                      <template v-slot:noOptions>
                        <span>{{ global.selectNotFoundData }}</span>
                      </template>
                    </multiselect>

                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Durée de couverture") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      type="date"
                      class="inputx w-full"
                      label="Date d'effet *"
                      v-model="startDateContributionsUpdated"
                      data-vv-validate-on="blur"
                      name="Date d'effet"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Date d'effet") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      type="date"
                      disabled
                      class="inputx w-full"
                      label="Date d'échéance *"
                      v-model="formUpdated.endDateContributions"
                      data-vv-validate-on="blur"
                      name="Date d'échéance"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Date d'échéance") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      type="number"
                      class="aps-benin-v2-form-number inputx w-full mt-2 mb-2"
                      label="Cotisation TTC*"
                      v-model="formUpdated.cotisationTTC"
                      v-validate="'required|numeric'"
                      data-vv-validate-on="blur"
                      name="Cotisation TTC"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("Cotisation TTC") }}
                    </div>
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      disabled
                      label="Périodicité de paiement"
                      value="Annuelle"
                    />
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4 mt-2 mb-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  style="flex-direction: column"
                >
                  <div class="w-full">
                    <p class="pl-2 pt-2">Objet*</p>
                    <multiselect
                      v-model="variableGlobalFormOjectUpdated"
                      :options="allMailVariableList"
                      class="mb-2 mt-2"
                      id="text"
                      placeholder="--Insérer une variable de personnalisation--"
                      label="label"
                      track-by="label"
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                    </multiselect>
                  </div>
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      v-model="formUpdated.object"
                      id="textoUpdated"
                      @change="onEditorChangeFormObjetUpdated($event)"
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      name="objet"
                    />
                    <div class="aps-benin-v2-error-msg">
                      {{ errors.first("objet") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  vs-justify="left"
                  vs-align="left"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="pr-4 mt-2 mb-2"
                >
                  <div class="mb-2">
                    <span>Message*</span>
                  </div>
                  <div class="w-full">
                    <multiselect
                      v-model="variableGlobalUpdatedForm"
                      :options="allMailVariableList"
                      class="mb-4"
                      placeholder="--Insérer une variable de personnalisation--"
                      label="label"
                      track-by="label"
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                    </multiselect>

                    <quill-editor
                      @focus="onEditorFocusUpdatedForm($event)"
                      @change="onEditorChangeUpdatedForm($event)"
                      v-model="formUpdated.message"
                    ></quill-editor>
                  </div>
                </vs-col>
                <vs-col class="pr-4 mb-2 mt-4" vs-lg="12" vs-sm="12" vs-xs="12">
                  <div
                    class="w-full"
                    style="
                      display: flex;
                      align-items: flex-end;
                      justify-content: space-between;
                    "
                  >
                    <div>
                      <div>
                        <span class="ml-2">Fichier(s)</span>
                        <div>
                          <div uk-form-custom class="ebsi-upload-file">
                            <input
                              multiple
                              type="file"
                              :value="resetFilesUpload"
                              v-on:change="checkUploadUpdatedFile"
                            />
                            <button
                              style="cursor: pointer"
                              class="uk-button uk-button-default"
                              type="button"
                              tabindex="-1"
                            >
                              Joindre des fichiers
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="aps-benin-v2-error-msg" v-show="formError">
                        Erreur lors du chargement du fichier
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-3 mb-3"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      class="mb-4"
                      v-if="
                        formUpdated.filesname &&
                        formUpdated.filesname.length > 0
                      "
                    >
                      <p><b>Liste des fichiers</b></p>
                    </div>
                    <div
                      v-for="(file, index) in formUpdated.filesname"
                      :key="file.name"
                      class="center"
                    >
                      <div class="flex" v-if="file.filename && file.name">
                        {{ index + 1 }})
                        <b
                          @click="checkViewFile(file)"
                          class="pl-2 cursor-pointer"
                          >{{ subString(file.name) }}</b
                        >
                        <vx-tooltip color="primary" text="Supprimer">
                          <div
                            class="ml-8 cursor-pointer"
                            @click="removeFileFromFilenameUpdated(index)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              svgClasses="w-6 h-6"
                            ></feather-icon>
                          </div>
                        </vx-tooltip>
                      </div>
                      <div v-else class="flex">
                        {{ index + 1 }}) <b>{{ subString(file) }}</b>
                        <vx-tooltip color="primary" text="Supprimer">
                          <div
                            class="ml-8 cursor-pointer"
                            @click="removeFile(index, 'Updated')"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              svgClasses="w-6 h-6"
                            ></feather-icon>
                          </div>
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
          <div class="mt-8">
            <div class="inline-flex">
              <div>
                <vs-button
                  class="w-full sm:w-auto ml-4"
                  :disabled="
                    !validateFormUpdatedAvenant || loadingUpdateAvenant
                  "
                  @click="fetchApplyUpdateAvenant"
                  >Valider</vs-button
                >
                <vs-button
                  class="w-full sm:w-auto ml-4"
                  type="border"
                  @click="popupUpdateAvenant = false"
                  >Annuler</vs-button
                >
              </div>
              <div class="ml-2">
                <clip-loader
                  :loading="loadingUpdateAvenant"
                  color="primary"
                ></clip-loader>
              </div>
            </div>
            <div class="mt-2">
              <p class="text-danger">* Champ obligatoire</p>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        class="holamundo"
        title="Liste des fichiers"
        :active.sync="popupListFiles"
      >
        <div v-if="filesList.length != 0" class="m-4">
          <p
            v-for="item in filesList"
            :key="item.filename"
            style="cursor: pointer"
            @click="checkViewFile(item)"
          >
            <b
              ><span> {{ item.name }} </span></b
            >
            <vs-divider />
          </p>
        </div>
        <div v-else>
          <div class="flex items-center justify-center mt-8">
            <strong>Aucun enregistrement</strong>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        class="holamundo"
        title="Comment souhaitez-vous payer la cotisation ?"
        :active.sync="popupPaiement"
      >
        <div class="mt-8">
          <div class="inline-flex">
            <div>
              <vs-button class="w-full sm:w-auto" @click="payedWithAgregator"
                >Payer en ligne</vs-button
              >
              <vs-button
                class="w-full sm:w-auto ml-4"
                type="border"
                @click="payedWithoutAgregator"
                >Marquer paiement</vs-button
              >
              <vs-button
                class="w-full sm:w-auto ml-4"
                type="border"
                @click="popupPaiement = false"
                >Annuler</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        class="holamundo"
        title="Paiement"
        :active.sync="popupMarkPayment"
      >
        <div class="m-4">
          <aps-benin-v2-subscribe-mark-payment
            v-on:onDisplayMarkPayment="onDisplayMarkPayment"
            crrModuleName="Avenant"
            :item="formUpdatedItem"
            :byProject="item"
            :byProductContentSelected="selectedAvenant"
            :isClo="popupMarkPayment"
          ></aps-benin-v2-subscribe-mark-payment>
        </div>
      </vs-popup>

      <vs-popup
        class="holamundo"
        title="Activer Avenant"
        :active.sync="popupEnabledAvenant"
      > 
        <div class="m-4">
          <aps-benin-v2-subscribe-enabled-avenant
            v-on:onDisplayAvenant="onDisplayAvenant"
            crrModuleName="Avenant"
            :item="formUpdatedItem"
          ></aps-benin-v2-subscribe-enabled-avenant>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  props: {
    crrModuleName: String,
    item: Object,
  },
  data() {
    return {
      selectedAvenant: {},
      variableGlobalFormOjectUpdated: "",
      formTakeOjetUpdated: {},
      cursorPositionObject: 0,
      cursorPositionObjectUpdated: 0,
      formTakeOjet: {},
      variableGlobalFormOject: "",
      startDateContributions: "",
      startDateContributionsUpdated: "",
      fileDataUp: [],
      fileDataUpUpdated: [],
      formUpdatedItem: {},
      quillEditorForm: {},
      variableGlobalForm: "",
      cursorPositionForm: 0,
      quillEditorUpdatedForm: {},
      variableGlobalUpdatedForm: "",
      cursorPositionUpdatedForm: 0,
      filesListBeforeUpdated: [],
      popupListFiles: false,
      filesList: [],
      brokerType: "",
      brokerUpdatedType: "",
      closeIt: true,
      role: {},
      global: {},
      fileData: [],
      resetFilesUpload: "",
      displayFileName: false,
      formData: [],
      formDataUpdated: [],
      formUpdatedError: false,
      formError: false,
      durationCoverageListSelect: [
        { id: 1, label: 1 },
        { id: 2, label: 2 },
        { id: 3, label: 3 },
        { id: 4, label: 4 },
        { id: 5, label: 5 },
        { id: 6, label: 6 },
        { id: 7, label: 7 },
        { id: 8, label: 8 },
        { id: 9, label: 9 },
        { id: 10, label: 10 },
      ],
      form: {
        startDateContributions: "",
        endDateContributions: "",
        cotisationTTC: "",
        durationCoverage: { id: 1, label: 1 },
        object: "{{Civilité}} {{Nom}}, votre nouvel appel de cotisation - {{Ref_Contrat}}",
        message: "",
        filesname: [],
      },
      formUpdated: {
        startDateContributions: "",
        endDateContributions: "",
        cotisationTTC: "",
        object: "",
        message: "",
        filesname: [],
      },

      loading: false,
      loadingRemoveAvenant: false,
      loadingUpdateAvenant: false,
      loadingAddAvenant: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",

      //export section
      logo: "",
      formats: ["xlsx", "csv", "txt"],//formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Nom", "Prénom", "Nom d'utilisateur", "Email"],
      headerVal: ["index", "lastname", "firstname", "username", "email"],
      activePrompt: false,
      //end export section
      popupAddAvenant: false,
      popupUpdateAvenant: false,
      avenantSelected: [],
      crrIDs: [],
      popupEnabledAvenant: false,
      popupPaiement: false,
      popupAgregateur: false,
      popupMarkPayment: false,
      loadingDocTempoDeces: false,
      tempo_deces_xls_doc: "",
      cotisationDefaultList: [],
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
  }, 
  mounted() {
    addKkiapayCloseListener(() => {
      this.closeIt = !this.closeIt
    })
    
    if (localStorage.getItem("byTypePayment") == "Avenant") {
      if (!window.callbackPaymentAvenantRegistred) {
        window.callbackPaymentAvenantRegistred = true;
        addSuccessListener((response) => {
          this.woIsAction = "";
          
          console.log("Avenant", response);
          if (response.transactionId) {
            this.acceptAlert({
              color: "success",
              text: "Paiement en cours de validation",
              title: "Paiement",
            })

            localStorage.removeItem("byTypePayment")  
            this.closeIt = true
          } else {
            this.acceptAlert({
              color: "danger",
              text: "Erreur lors du paiement",
              title: "Paiement",
            });
          }
          window.callbackPaymentAvenantRegistred = false
        })
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("users", ["allUsers"]),
    ...mapGetters("suscribe", ["currentSuscribeData"]),
    ...mapGetters("general", [
      "allDocClient",
      "paymentUserTypes",
      "allPaymentStatus",
      "allMailVariableText",
      "allMailVariableList",
      "productNature",
      "paymentMethod",
        "allPeriodicityOfPayment",
    ]),

    productName() {
      if(this.crrModuleName == "SOHU") {
        return "SOHU"
      }
    },

    validateFormAddAvenant() {
      return (
        !this.errors.any() &&
        this.form.startDateContributions != "" &&
        this.form.endDateContributions != "" &&
        this.form.cotisationTTC != "" &&
        this.form.object != "" &&
        this.form.message != ""
      );
    },
    validateFormUpdatedAvenant() {
      return (
        !this.errors.any() &&
        this.formUpdated.startDateContributions != "" &&
        this.formUpdated.endDateContributions != "" &&
        this.formUpdated.cotisationTTC != "" &&
        this.formUpdated.object != "" &&
        this.formUpdated.message != ""
      );
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("general", ["applyUploadFile", "applyGetItemDocument", "applyGenerateCertificat", "applyGenerateCotisation", "applyGenerateCotisationSolde", ]),
    ...mapActions("suscribe", [
      "applyAddAvenant",
      "applyPutAvenant",
      "applyRemoveAvenant",
      "applyGetProject",
      "applySentProject",
      "applyChangeAvenantState",
    ]),
 
        formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    onGetSubscriber(data) {
      if (data) {
        return {
          subscriber_sexe: data.civility,
          subscriber_clientNumber: data.clientNumber,
          subscriber_lastname: data.lastname,
          subscriber_firstname: data.firstname,
          subscriber_email: data.email,
          subscriber_birthday: this.formatDate(data.birthday),
          subscriber_phone: data.phone,
          subscriber_commune: data.commune,
          subscriber_adress: data.adress,
          // subscriber_categorySocioProfessional: this.allCategorySocPro.find(
          //   (el) => el.id == data.categorySocioProfessional
          // )
          //   ? this.allCategorySocPro.find(
          //       (el) => el.id == data.categorySocioProfessional
          //     ).label
          //   : "",
          // subscriber_familySituation: this.allFamilySituation.find(
          //   (el) => el.id == data.familySituation
          // )
          //   ? this.allFamilySituation.find(
          //       (el) => el.id == data.familySituation
          //     ).label
          //   : "",
          subscriber_profession: data.profession,
          subscriber_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          subscriber_sexe: "",
          subscriber_lastname: "",
          subscriber_firstname: "",
          subscriber_email: "",
          subscriber_birthday: "",
          subscriber_phone: "",
          subscriber_commune: "",
          subscriber_adress: "",
          // subscriber_categorySocioProfessional: "",
          // subscriber_familySituation: "",
          subscriber_profession: "",
          subscriber_identity: "",
        };
      }
    },
    onGetAssured(data) {
      if (data) {
        return {
          // assured_sexe: data.civility,
          assured_lastname: data.lastname,
          assured_firstname: data.firstname,
          assured_email: data.email,
          assured_birthday: this.formatDate(data.birthday),
          assured_phone: data.phone,
          assured_commune: data.commune,
          assured_adress: data.adress,
          // assured_categorySocioProfessional: this.allCategorySocioPro.find(
          //   (el) => el.id == data.categorySocioProfessional
          // )
          //   ? this.allCategorySocioPro.find(
          //       (el) => el.id == data.categorySocioProfessional
          //     ).label
          //   : "",
          // assured_familySituation: this.familySituation.find(
          //   (el) => el.id == data.familySituation
          // )
          //   ? this.familySituation.find((el) => el.id == data.familySituation)
          //       .label
          //   : "",
          assured_profession: data.profession,
          // assured_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          // assured_sexe: "",
          assured_lastname: "",
          assured_firstname: "",
          assured_email: "",
          assured_birthday: "",
          assured_phone: "",
          assured_commune: "",
          assured_adress: "",
          // assured_categorySocioProfessional: "",
          // assured_familySituation: "",
          assured_profession: "",
          // assured_identity: "",
        };
      }
    },
   onGetBeneficiariesDeceased(data) {
      if (data) {
        return {
          BeneficiariesDeceased_name:
          data.name == "" && data.value ==""?
          `${data.customForm.lastname}, ${data.customForm.firstname}, ${this.formatDate(data.customForm.birthday )}`:
            data.name == "myPartner"
              ? " Mon conjoint, à défaut mes enfants nés et à naître, à défaut mes ayants droit"
              : `${data.value}`
        };
      } else {
        return {
          BeneficiariesDeceased_name: "",
        };
      }
    },
    onGetTarif(productName, data) {
      console.log(productName, data, "(productName, data) ");
      if (productName == "SOHU") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            accessoir: propositionChoose.accessoir,
            amountOfCapital: propositionChoose.amountOfCapital,
            capitalDeathAllCauses: propositionChoose.capitalDeathAllCauses,
            accumulationContributions:
              propositionChoose.accumulationContributions,
            absoluteDefinitiveDisabilityCapital:
              propositionChoose.absoluteDefinitiveDisabilityCapital,
            corverageDuration: propositionChoose.corverageDuration,
            doublingDeathBenefit:
              propositionChoose.doublingDeathBenefit == true ? "Oui" : "Non",
            partialPermanentDisability:
              propositionChoose.partialPermanentDisability == true
                ? "Oui"
                : "Non",
          };
        } else {
          return {
            accessoir: "",
            amountOfCapital: "",
            capitalDeathAllCauses: "",
            accumulationContributions: "",
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: "",
            doublingDeathBenefit: "",
            partialPermanentDisability: "",
          };
        }
      } else if (productName == "AHR") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            ahr_amountOfCapital: propositionChoose.amountOfCapital,
            ahr_contributionPeriod: "Mensuelle",
            ahr_corverageDuration: propositionChoose.corverageDuration,
            ahr_startDateContributions: this.formatDate(data.startDateContributions),
            ahr_endDateContributions: this.formatDate(propositionChoose.endDateContributions),
            ahr_numberOfContributions: propositionChoose.numberOfContributions,
            ahr_accumulationOfContributions:
              propositionChoose.accumulationOfContributions,
            ahr_accumulationContributions:
              propositionChoose.accumulationContributions,
          };
        } else {
          return {
            ahr_amountOfCapital: "",
            ahr_contributionPeriod: "",
            ahr_corverageDuration: "",
            ahr_startDateContributions: "",
            ahr_endDateContributions: "",
            ahr_numberOfContributions: "",
            ahr_accumulationOfContributions: "",
            ahr_accumulationContributions: "",
          };
        }
      } else if (productName == "AAE") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            aae_amountOfCapital: propositionChoose.amountOfCapital,
            aae_deathOrAbsoluteDefinitiveDisability:
              propositionChoose.deathOrAbsoluteDefinitiveDisability
                ? "Oui"
                : "Non",
            aae_periodicityOfPayment: this.allPeriodicityOfPayment.find(
              (el) => el.id == propositionChoose.periodicityOfPayment
            )
              ? this.allPeriodicityOfPayment.find(
                  (el) => el.id == propositionChoose.periodicityOfPayment
                ).label
              : "",

            aae_corverageDuration: propositionChoose.corverageDuration,
            aae_startDateContributions: this.formatDate( data.startDateContributions,
            ),
            aae_endDateContributions: this.formatDate( propositionChoose.endDateContributions,
            ),
            aae_numberOfContributions: propositionChoose.numberOfContributions,
            aae_accumulationOfContributions:
              propositionChoose.accumulationOfContributions,
            aae_accumulationContributions:
              propositionChoose.accumulationContributions,
          };
        } else {
          return {
            aae_amountOfCapital: "",
            aae_periodicityOfPayment: "",
            aae_deathOrAbsoluteDefinitiveDisability: "",
            aae_corverageDuration: "",
            aae_startDateContributions: "",
            aae_endDateContributions: "",
            aae_numberOfContributions: "",
            aae_accumulationOfContributions: "",
            aae_accumulationContributions: "",
          };
        }
      }
    },
    onFormatAmount(amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    enabledAvenant(item) {
      this.formUpdatedItem = item;
      this.popupEnabledAvenant = true;
    },
    whoIs(type) {
      let types = this.paymentUserTypes
      return types[type]
    },
    async fetchTarifsDocContent(data) {
      this.loadingDocTempoDeces = true;

      try {
        let resItemDoc = await this.applyGetItemDocument({
          code: "tarifs_sohu",
          productName: this.allDocClient[this.item.productName],
        });

        this.tempo_deces_xls_doc = resItemDoc.data
          ? resItemDoc.data.filename
          : "";

        this.byGetTarifsContentFile();

        this.loadingDocTempoDeces = false;
        this.error = false;
      } catch (err) {
        this.loadingDocTempoDeces = false;
        this.error = true;
        console.log("err", err);
      }
    },

    byGetTarifsContentFile() { 
      mixin.methods
        .getAmountAndCotisationAPF(this.tempo_deces_xls_doc)
        .then((res) => {
          if (res.Cotisation) {
            this.cotisationDefaultList = []
            res.Cotisation.forEach((el) => {
              this.cotisationDefaultList.push({
                id: el,
                label: el,
              });
            });

            console.log(
              "cotisationDefaultList FILE EXCELL",
              this.cotisationDefaultList
            );
          }
        });
    },
    DetailsPayment(avenant, subscriberProfil) { 
      let profil = this.whoIs(avenant.paymentRequest.data.userType) 
      let userFullname = avenant.paymentRequest.data.userFullname
      let type = this.paymentMethod.find((el) => el.id == avenant.paymentRequest.type).label
      let time = avenant.paymentRequest.data.datePayment
      let comment = avenant.paymentRequest.data.comment ? avenant.paymentRequest.data.comment : ""

      let x = `Payé par ${profil ? profil : "Souscripteur"} (${userFullname ? userFullname : subscriberProfil}) - Date : ${time} - Mode paiement : ${type}, Commentaire : ${comment}`
      return x
    },
 
    async getProject() {
      let data = {
        projectId: this.item.id,
      };

      this.loading = true;

      try {
        await this.applyGetProject(data);

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    /* Form Objet */
    getInputSelection(el) {
      let start = 0,
        end = 0,
        normalizedValue,
        range,
        textInputRange,
        len,
        endRange;

      if (
        typeof el.selectionStart == "number" &&
        typeof el.selectionEnd == "number"
      ) {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
            start = end = len;
          } else {
            start = -textInputRange.moveStart("character", -len);
            start += normalizedValue.slice(0, start).split("\n").length - 1;

            if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
              end = len;
            } else {
              end = -textInputRange.moveEnd("character", -len);
              end += normalizedValue.slice(0, end).split("\n").length - 1;
            }
          }
        }
      }

      return {
        start: start,
        end: end,
      };
    },

    onEditorChangeFormObjet(position) {
      let input = document.getElementById("texto");
      input.focus();
      this.cursorPositionObject = this.getInputSelection(input).start;
      this.formTakeOjet = position;
    },
    insertTextFormObjet(data) {
      this.form.object = [
        this.form.object.slice(0, this.cursorPositionObject),
        `${data} `,
        this.form.object.slice(this.cursorPositionObject),
      ].join("");
      this.cursorPositionObject = this.form.object.length;
    },
    /* End Form Objet */

    /* Form Objet modifier */

    onEditorChangeFormObjetUpdated(position) {
      let input = document.getElementById("textoUpdated");
      input.focus();
      this.cursorPositionObjectUpdated = this.getInputSelection(input).start;
      this.formTakeOjetUpdated = position;
    },
    insertTextFormObjetUpdated(data) {
      this.formUpdated.object = [
        this.formUpdated.object.slice(0, this.cursorPositionObjectUpdated),
        `${data} `,
        this.formUpdated.object.slice(this.cursorPositionObjectUpdated),
      ].join("");
      this.cursorPositionObjectUpdated = this.formUpdated.object.length;
    },
    /* End Form Objet modifier */

    /* Form*/
    onEditorChangeForm(quill) {
      this.cursorPositionForm = quill.quill.getSelection(quill).index;
      this.quillEditorForm = quill.quill;
    },
    onEditorFocusForm(quill) {
      this.cursorPositionForm = quill.getSelection();
      this.quillEditorForm = quill;
    },
    insertTextForm(data) {
      this.quillEditorForm.insertText(this.cursorPositionForm, `${data} `);
      this.cursorPositionForm = this.form.message.length - 7;
    },
    onEditorChangeUpdatedForm(quill) {
      this.cursorPositionUpdatedForm = quill.quill.getSelection(quill).index;
      this.quillEditorUpdatedForm = quill.quill;
    },
    onEditorFocusUpdatedForm(quill) {
      this.cursorPositionUpdatedForm = quill.getSelection();
      this.quillEditorUpdatedForm = quill;
    },
    insertTextUpdatedForm(data) {
      this.quillEditorUpdatedForm.insertText(
        this.cursorPositionUpdatedForm,
        `${data} `
      );
      this.cursorPositionUpdatedForm = this.formUpdated.message.length - 7;
    },
    /* End Form*/

    checkCancelPayed(item) {
      this.crrID = item.id;
      this.formUpdatedItem = { ...item };
      
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment annuler le paiement sur ce avenant ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.cancelPayedAvenant,
      });
    },

    async fetchApplyPutAvenant(paymentRequest) {
      if(this.selectedAvenant.id) {
        let crr = {
          ...this.selectedAvenant,
          avenantId: this.selectedAvenant.id,
          transactionId: paymentRequest.data.transactionId,
          paymentRequest,
        };
        await this.applyPutAvenant(crr);
      }
    },

    async cancelPayedAvenant() {
      this.woIsAction = this.crrID;
      this.loadingUpdateAvenant = true;

      let data = {
        avenantId: this.crrID,
        ...this.formUpdatedItem,
        paymentStatus: "required",
        cancelPay: true,
      }

      try {
        await this.applyChangeAvenantState(data).then((res) => {
          this.acceptAlert(
            mixin.methods.message("subscription", "cancelPayment").success
          );
          this.formUpdatedItem = {}

          this.formUpdated = {
            startDateContributions: "",
            endDateContributions: "",
            cotisationTTC: "",
            object: "",
            message: "",
            filesname: [],
          };
          this.loadingUpdateAvenant = false;
          this.woIsAction = "";
          this.avenantSelected = [];
        });
      } catch (err) {
        this.loadingUpdateAvenant = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    checkPayed(item) {
      this.selectedAvenant = item;
      this.crrID = item.id;
      this.formUpdatedItem = { ...item };
      this.popupPaiement = true;
    },

    checkUploadFile(evt) {
      console.log("evt", evt);
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();

        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;

        allFilesChecked.push(evt.target.files[i].name);

        formDataLength++;
      }
      console.log("allFilesChecked", allFilesChecked)
      this.form.filesname = [...this.form.filesname, ...allFilesChecked];
      this.displayFileName = true;
    },

    checkUploadUpdatedFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formDataUpdated.length;
      console.log("evtLength", evtLength)
      
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formDataUpdated[formDataLength + 1] = formDataTmp;
        this.formErrorUpdated = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }

      if(this.formUpdated.filesname) {
        this.formUpdated.filesname = [
          ...this.formUpdated.filesname,
          ...allFilesChecked,
        ]
      } else {
        this.formUpdated.filesname = [
          ...allFilesChecked,
        ]
      }
      
      this.displayFileName = true;
    },

    async uploadedFiles(option) {
      let _this = this;
      if (option == "Add") {
        let productPromises = [];
        this.formData.forEach(function (ele, index) {
          productPromises.push(
            (async function (ele) {
              const res = await _this.applyUploadFile(ele);
              _this.fileDataUp.push({
                filename: res.data[0].uploadedName,
                name: res.data[0].filename,
              });
            })(ele)
          );
        });
        await Promise.all(productPromises);
      } else {
        let productPromises = [];
        this.formDataUpdated.forEach(function (ele, index) {
          productPromises.push(
            (async function (ele) {
              const res = await _this.applyUploadFile(ele);
              _this.fileDataUpUpdated.push({
                filename: res.data[0].uploadedName,
                name: res.data[0].filename,
              });
            })(ele)
          );
        });
        await Promise.all(productPromises);
      }
    },

    removeFile(data, option) {
      if (option == "Add") {
        this.formData.splice(data, 1);
        this.form.filesname.splice(data, 1);
      } else {
        this.formDataUpdated.splice(data, 1);
        this.formUpdated.filesname.splice(data, 1);
      }
    },

    removeFileFromFilenameUpdated(item) {
      let data = [...this.formUpdated.filesname];
      const index = this.formUpdated.filesname.findIndex((el) =>
        el.filename ? el.filename === item.filename : el === item.filename
      );
      data.splice(index, 1);
      this.formUpdated.filesname = data;
      this.filesListBeforeUpdated = data;
    },

    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },

    onDisplayMarkPayment(isClose) {
      this.popupMarkPayment = isClose;
    },

    onDisplayAvenant(isClose) {
      this.popupEnabledAvenant = isClose;
    },

    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    payedWithAgregator() {
      this.popupPaiement = false;
      this.closeIt = false;

      let newData = {}
      let newAmount = this.formUpdatedItem.cotisationTTC

      if(this.crrModuleName == "SOHU") {
        newData = { avenantId: this.formUpdatedItem.id }
      }
      
      localStorage.setItem("byTypePayment", "Avenant")

      openKkiapayWidget({
        amount: newAmount, 
        position:"right",
        callback:"",
        data: {
          ...newData, 
          paymentRequest: {
            type: "momoCard",
            data: {
              datePayment: moment(new Date()).format("D MMMM YYYY à HH:mm"),
              userType: this.user.type,
              userId: this.user.id,
              userFullname: this.user.person.firstname + " " + this.user.person.lastname,
            },
          }
        },
        theme:"#AEC340",
        sandbox:"false", // changer in PROD
        key: global.APIKEYKKIAPAY, // change in PROD : APIKEYKKIAPAY
      })
    },

    payedWithoutAgregator() {
      this.popupPaiement = false;
      this.popupMarkPayment = true;
    },

    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },

    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    getFilesList(item) {
      this.filesList = item.filesname ? item.filesname : [];
      this.popupListFiles = true;
    },

    checkViewFile(item) {
      console.log("item", item);
      window.open(this.showFileData(item.filename), "_blank");
    },

    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },

    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.currentSuscribeData.avenants.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.profilList.find((type) => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];
        this.currentSuscribeData.avenants.forEach((el) => {
          let jsonTmp = {
            ref: this.currentSuscribeData.ref,
            refContrat: this.currentSuscribeData.refContrat,
            ...this.onGetSubscriber(this.currentSuscribeData.subscription.subscriber),
            ...this.onGetAssured(this.currentSuscribeData.subscription.assured),
            cotisationTTC: el.isDefault? ( parseInt(el.cotisationTTC) - parseInt(el.accessoir) ): el.cotisationTTC,
            accessoir: el.isDefault? el.accessoir: 0,

          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "ref",
          "refContrat",

          "subscriber_lastname",
          "subscriber_firstname",
          "subscriber_birthday",
          "subscriber_commune",
          "subscriber_phone",
          "subscriber_profession",
          "subscriber_email",
          "subscriber_adress",
          // "PIECE SOUSCRIPTEUR", "N°PIECE SOUSCRIPTEUR",
          "assured_lastname",
          "assured_firstname",
          "assured_birthday",
          "assured_commune",
          "assured_phone",
          "assured_profession",
          "assured_email",
          "assured_adress",



        ];

        let headerTitleTmp = [
          "Référence devis",	
          "Référence contrat",

          "Numéro avenant",

          "Numéro client",
          "Civilité souscripteur",
          "Nom souscritpeur",
          "Prénoms souscritpeur",
          "Date de naissance souscritpeur",
          "Commune de naissance souscritpeur",
          "Adresse souscripteur",
          "E-mail souscritpeur",
          "Téléphone souscritpeur",
          "Profession souscritpeur",

          "Civilité assuré",
          "Nom assuré",
          "Prénoms assuré",
          "Date de naissance assuré",
          "Commune de naissance assuré",
          "Adresse assuré",
          "E-mail assuré",
          "Téléphone assuré",
          "Situation familiale assuré",
          "Catégorie socioprofessionnelle assuré",
          "Profession assuré",
          "Type Pièce d'identité assuré",
          "N° Pièce d'identité assuré",

                    "Bénéficiaire capital décès",


          "NATURE PRODUIT",
          "NOM PRODUIT",

          "Capital Décès Toutes Causes",
          "Capital Invalidité Absolue et Définitive",
          "Doublement du capital décès",
          "Invalidité Partielle et Permanente",
          "Cotisation nette",
          "Accessoire",
          "Cotisation totale",
          "Périodicité de la cotisation",
          "Durée de couverture (année)",
          "Date d'effet",
          "Date d'échéance",

          "Mode de paiement",
          "Référence paiement",
          "Commentaires"
];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },

    async fetchApplyAddAvenant() {
      if (!this.validateFormAddAvenant) return false;
      this.loadingAddAvenant = true;

      let data = {
        ...this.form,
        durationCoverage: this.form.durationCoverage.id,
        cotisationTTC: this.form.cotisationTTC,
        paymentAmount: this.form.cotisationTTC,
        projectId: this.item.id, 
        isDefault: false,
        filesname: this.fileDataUp,
      };

      try {
        if (this.form.filesname.length > 0) {
          await this.uploadedFiles("Add")
            .then((res) => {
              setTimeout(async () => {
                this.acceptAlert(
                  mixin.methods.message("subscription", "file").success
                );

                let xAvenant = await this.applyAddAvenant(data)
                this.fetchApplySentMailProject(false, xAvenant);

              }, 100);
            })
            .catch((err) => {
              console.log(err);
              this.acceptAlert(
                mixin.methods.message("subscription", "file").error
              );
              
              setTimeout(async () => {
                let xAvenant = await this.applyAddAvenant(data)
                this.fetchApplySentMailProject(false, xAvenant);
              }, 100)
            });
        } else {
          setTimeout(async () => {
            let xAvenant = await this.applyAddAvenant(data)
            this.fetchApplySentMailProject(false, xAvenant);
          }, 1000)
        }
      } catch (err) {
        this.loadingAddAvenant = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Le nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Ajout",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    async fetchApplySentMailProject(isUpdated, xAvenant) {
      if (isUpdated) {
        this.loadingUpdateAvenant = true;
        if (!this.validateFormUpdatedAvenant) return false;

        let gCotisation =  await this.applyGenerateCotisation({ projectId: this.item.id, avenantId: xAvenant.id })
 
        let data = {
          object: this.formUpdated.object,
          message: this.formUpdated.message,
          filesname: [
            {
              name: gCotisation.data.docClient.name+".pdf",
              filename: gCotisation.data.currPdfName,
            },
            ...this.fileDataUpUpdated,
          ],
          subscriberId: this.item.subscription.subscriber.id,
          projectId: this.item.id,
          brokerId: this.item.broker ? this.item.broker.id : "",
        };
        console.log('data', data)
        
        try {
          setTimeout(async () => {
            await this.applySentProject(data).then((res) => {
              this.loadingUpdateAvenant = false;
              this.acceptAlert(
                mixin.methods.message("subscription", "updateAvenant").success
              )

              this.formUpdated = {
                startDateContributions: "",
                endDateContributions: "",
                cotisationTTC: "",
                durationCoverage: { id: 1, label: 1 },
                object: "{{Civilité}} {{Nom}}, votre nouvel appel de cotisation - {{Ref_Contrat}}",
                message: "",
                filesname: [],
              };
              this.startDateContributions = ""
              this.formDataUpdated = [];
              this.displayFileName = false;
              this.fileDataUpUpdated = [];
              this.filesListBeforeUpdated = [];

              this.loadingUpdateAvenant = false;
              this.popupUpdateAvenant = false;
              this.getProject();
            });
          }, 1000)

        } catch (err) {
          this.loadingUpdateAvenant = false;
          this.error = true;
          this.errorMsg = "";
        }
      } else {
        this.loadingAddAvenant = true;
        if (!this.validateFormAddAvenant) return false;

        let gCotisation =  await this.applyGenerateCotisation({ projectId: this.item.id, avenantId: xAvenant.id })

        let data = {
          object: this.form.object,
          message: this.form.message,
          filesname: [
            {
              name: gCotisation.data.docClient.name+".pdf",
              filename: gCotisation.data.currPdfName,
            },
            ...this.fileDataUp,
          ],
          subscriberId: this.item.subscription.subscriber.id,
          projectId: this.item.id,
          brokerId: this.item.broker ? this.item.broker.id : "",
        };
        try {
          setTimeout(async () => {
            await this.applySentProject(data).then((res) => {
              this.loadingAddAvenant = false;
              this.form = {
                startDateContributions: "",
                endDateContributions: "",
                cotisationTTC: "",
                durationCoverage: { id: 1, label: 1 },
                object: "{{Civilité}} {{Nom}}, votre nouvel appel de cotisation - {{Ref_Contrat}}",
                message: "",
                filesname: [],
              };
              this.startDateContributions = ""
              this.fileDataUp = [];
              this.acceptAlert(
                mixin.methods.message("subscription", "addAvenant").success
              );

              this.popupAddAvenant = false;
              // this.getProject();
            })
          }, 1000)
        } catch (err) {
          this.loadingAddAvenant = false;
          this.error = true;
          this.errorMsg = "";
        }
      }
    },

    checkUpdateAvenant(item) {
      console.log(item);

      this.formUpdated.filesname = [];

      this.variableGlobalUpdatedForm = "";
      this.quillEditorUpdatedForm = {};

      this.crrID = item.id;
      this.formUpdated = { ...item, durationCoverage: { id: item.durationCoverage, label: item.durationCoverage }, object: "{{Civilité}} {{Nom}}, votre nouvel appel de cotisation - {{Ref_Contrat}}", message: "" };
      this.startDateContributionsUpdated = item.startDateContributions;
      this.filesListBeforeUpdated = this.formUpdated.filesname;
      this.formUpdated.filesname = [];
      
      this.popupUpdateAvenant = true;
    },

    async fetchApplyUpdateAvenant() {
      if (!this.validateFormUpdatedAvenant) return false;

      let data = {
        avenantId: this.crrID,
        ...this.formUpdated,
        durationCoverage: this.formUpdated.durationCoverage.id,
        filesname: [...this.fileDataUpUpdated],
      };
      console.log("data", data)

      this.loadingUpdateAvenant = true;

      try {
        if (this.formUpdated.filesname.length > 0) {
          await this.uploadedFiles("Updated")
            .then((res) => {
              setTimeout(async () => {
                this.acceptAlert(
                  mixin.methods.message("subscription", "file").success
                );
                console.log('fileDataUpUpdated', this.fileDataUpUpdated)

                let xAvenant = await this.applyPutAvenant(data)
                this.fetchApplySentMailProject(true, xAvenant);

              }, 100);
            })
            .catch((err) => {
              console.log(err);
              this.acceptAlert(
                mixin.methods.message("subscription", "file").error
              );

              setTimeout(async () => {
                let xAvenant = await this.applyPutAvenant(data)
                this.fetchApplySentMailProject(true, xAvenant);
              }, 100)
            });
        } else {
          setTimeout(async () => {
            let xAvenant = await this.applyPutAvenant(data)
            this.fetchApplySentMailProject(true, xAvenant);
          }, 100)
        }
      } catch (err) {
        this.loadingUpdateAvenant = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupUpdateAvenant = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    // checkRemoveMultipleAvenant() {
    //   if (this.avenantSelected.find(avenant => avenant.isDefault == true)) {
    //     this.acceptAlert("danger", "SUPPRESSION", role.notAllowText);
    //   } else {
    //     this.avenantSelected.forEach(element => {
    //       this.crrIDs.push(element.id);
    //     });
    //     this.$vs.dialog({
    //       type: "confirm",
    //       color: "danger",
    //       title: `Confirmation`,
    //       text: `Voulez-vous vraiment supprimer les éléments sélectionnés ?`,
    //       acceptText: "Confirmer",
    //       cancelText: "Annuler",
    //       accept: this.fetchRemoveMultipleAvenant
    //     });
    //   }
    // },
    // async fetchRemoveMultipleAvenant() {
    //   let data = {
    //     ids: this.crrIDs
    //   };

    //   this.loading = true;
    //   try {
    //     await this.applyRemoveAvenant(data);
    //     this.loading = false;
    //     this.acceptAlert(
    //       "success",
    //       "Suppression",
    //       "Les avenants sélectionnés ont été supprimés avec succès"
    //     );
    //     this.crrIDs = [];
    //     this.avenantSelected = [];
    //   } catch (err) {
    //     this.loading = false;
    //     this.crrIDs = [];
    //     this.avenantSelected = [];

    //     this.error = true;
    //     this.errorMsg = "";

    //     if (err.response) {
    //       if (
    //         err.response.status &&
    //         err.response.statusText === "Unauthorized"
    //       ) {
    //         this.acceptAlert(
    //           "danger",
    //           "Authentification",
    //           "Votre session a expirée"
    //         );
    //         await this.logout();
    //         this.$router.push({ name: "Login" });
    //       } else {
    //         err.response.data.errors.forEach(item => {
    //           switch (item.code) {
    //             default:
    //               this.acceptAlert(
    //                 "danger",
    //                 "Suppression",
    //                 "Une erreur s'est produite"
    //               );
    //               break;
    //           }
    //         });
    //       }
    //     }
    //   }
    // },
    checkRemoveAvenant(avenant) {
      this.crrID = avenant.id;
      this.formUpdated = { ...avenant };

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer l'avenant ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveAvenant,
      });
    },
    async fetchApplyRemoveAvenant() {
      this.woIsAction = this.crrID;

      let data = {
        ids: [this.crrID],
      };
      this.loadingRemoveAvenant = true;
      try {
        await this.applyRemoveAvenant(data).then((res) => {
          if (this.formUpdated.isDefault) {
            this.acceptAlert(
              mixin.methods.message("subscription", "deleteAvenant").success
            );

            this.loadingRemoveAvenant = false;
            this.woIsAction = "";
            this.avenantSelected = [];
            this.$emit("onDisplayAvenant", false);
          } else {
            this.loadingRemoveAvenant = false;
            this.acceptAlert(
              mixin.methods.message("subscription", "deleteAvenant").success
            );

            this.woIsAction = "";
            this.avenantSelected = [];
          }
        });
      } catch (err) {
        this.loadingRemoveAvenant = false;
        this.woIsAction = "";
        this.avenantSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Utilisateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "deleteAvenant").error
                  );

                  break;
              }
            });
          }
        }
      }
    },

    avenantSelectedDouble(item) {
      if (this.checkUserRole("broker").show) {
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
  },
  watch: {
    startDateContributions() {
      this.form.startDateContributions = this.startDateContributions;
      this.form.endDateContributions = mixin.methods
        .formatDate(
          mixin.methods.calculDateInFutur(
            this.startDateContributions,
            this.form.durationCoverage.id,
          )
        ) 
        .split("/")
        .join("-");
    },
    "form.durationCoverage"(newValue) {
      this.form.endDateContributions = mixin.methods
        .formatDate(
          mixin.methods.calculDateInFutur(
            this.startDateContributions,
            newValue.id,
          )
        )
        .split("/")
        .join("-");
    },
    startDateContributionsUpdated() {
      this.formUpdated.startDateContributions = this.startDateContributionsUpdated;
      this.formUpdated.endDateContributions = mixin.methods
        .formatDate(
          mixin.methods.calculDateInFutur(
            this.startDateContributionsUpdated,
            this.formUpdated.durationCoverage.id
          )
        )
        .split("/")
        .join("-");
    },
    "formUpdated.durationCoverage"(newValue) {
      this.formUpdated.endDateContributions = mixin.methods
        .formatDate(
          mixin.methods.calculDateInFutur(
            this.startDateContributionsUpdated,
            newValue.id,
          )
        )
        .split("/")
        .join("-");
    },

    item() {
      if (this.item.id) {
        this.getProject();
        //this.fetchTarifsDocContent()
      }
    },
    variableGlobalFormOject() {
      if (this.formTakeOjet) {
        this.insertTextFormObjet(this.variableGlobalFormOject.id);
      }
    },
    variableGlobalFormOjectUpdated() {
      if (this.formTakeOjetUpdated) {
        this.insertTextFormObjetUpdated(this.variableGlobalFormOjectUpdated.id);
      }
    },
    variableGlobalForm() {
      if (this.quillEditorForm.selection) {
        this.insertTextForm(this.variableGlobalForm.id);
      }
    },
    variableGlobalUpdatedForm() {
      if (this.quillEditorUpdatedForm.selection) {
        this.insertTextUpdatedForm(this.variableGlobalUpdatedForm.id);
      }
    },
    popupPaiement(val) {
      this.$emit("onDisplayAvenant", !this.popupPaiement);
    },
    closeIt(val) {
      this.$emit("onDisplayAvenant", val);
    },
    popupAgregateur() {
      this.$emit("onDisplayAvenant", !this.popupAgregateur);
    },
    popupMarkPayment() {
      this.$emit("onDisplayAvenant", !this.popupMarkPayment);
    },

    popupAddAvenant() {
      this.$emit("onDisplayAvenant", !this.popupAddAvenant);
    },

    popupEnabledAvenant() {
      this.$emit("onDisplayAvenant", !this.popupEnabledAvenant);
    },

    popupUpdateAvenant() {
      this.$emit("onDisplayAvenant", !this.popupUpdateAvenant);
    },

    popupListFiles() {
      this.$emit("onDisplayAvenant", !this.popupListFiles);
    },
  },
  components: {
    ClipLoader,
    Multiselect,
    quillEditor,
  },
};
</script>

<style lang="scss">
.aps-benin-v2-icon-clickable {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.aps-benin-v2-input-container {
  border: 2px solid #a0a0a0;
  text-align: center;
  padding: 10px;
  padding-bottom: 10px;
}
</style>
